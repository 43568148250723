<template lang="html">
	<v-layout column class="pb-5" style="padding-top:140px">
		<v-flex class="animation-bar" :style="{'height':$vuetify.breakpoint.smAndUp?'90vh':'40vh'}">
			<v-layout column style="height:calc(100% - 50px);padding:50px 0 0">
				<v-flex>
					<v-layout row>
						<v-flex xs12 sm10 md9 class="openingLine-wrapper" style="font-family:JostMedium; z-index:9;" :style="{'font-size':$vuetify.breakpoint.smAndUp?'64px':'24px'}">
							<span v-html="openingLine.computed"></span>
							<!-- <div class="typing-animation" :style="{'animation-delay':$store.getters.getFirstTimeLoadValue?'3.8s':'0.1s'}">
								Historically companies have grown by
							</div>
							<div class="typing-animation" :style="{'animation-delay':$store.getters.getFirstTimeLoadValue?'6.4s':'2.7s'}">
								hiring employees, engaging partners and
							</div>
							<div class="typing-animation" :style="{'animation-delay':$store.getters.getFirstTimeLoadValue?'8.6s':'4.9s'}">
								acquiring new businesses.
							</div> -->
						</v-flex>
					</v-layout>
				</v-flex>
				<!--<v-flex :style="{'margin-top':$vuetify.breakpoint.xsOnly?'20px':'0'}">
					<v-layout row justify-end>
						<v-flex xs10 style="text-align:right;font-family:JostBold;" :style="{'font-size':$vuetify.breakpoint.smAndUp?'4vw':'28px'}">
							<div class="fade-in-animation" :style="{'animation-delay':$store.getters.getFirstTimeLoadValue?'10.2s':'6.5s'}">
								Is there a
								<div class="swipe-color" :style="{'animation-delay':$store.getters.getFirstTimeLoadValue?'11.2s':'7.5s'}">
									fourth
									<div class="swipe-color-overlay" :style="{'animation-delay':$store.getters.getFirstTimeLoadValue?'11.2s':'7.5s'}"></div>
								</div>
								way to grow?
							</div>
							<div class="fade-in-animation" :style="{'animation-delay':$store.getters.getFirstTimeLoadValue?'9.5s':'5.8s'}">
								Is there a <span style="color:#DFC24E">fourth</span> way to grow?
							</div>
						</v-flex>
					</v-layout>
				</v-flex>-->
			</v-layout>
		</v-flex>
		<v-flex style="position:relative" :style="{'height':$vuetify.breakpoint.smAndUp?'auto':'100px'}">
			<div class="landing-scroll-bar" :style="{'animation-delay':$store.getters.getFirstTimeLoadValue?'8s':'7s', 'top':$vuetify.breakpoint.smAndUp?'-150px':'-60px', 'height':$vuetify.breakpoint.smAndUp?'150px':'100px'}">
				<div class="landing-scroll-ball"></div>
			</div>
		</v-flex>
		<v-flex class="imagineBanner-contentSection">
		<div class="grid-container">
			<div class="grid-item"></div>
			<div class="grid-item"></div>
			<div class="grid-item"></div>
			<div class="grid-item"></div>
			<div class="grid-item"></div>
			<div class="grid-item"></div>
			<div class="grid-item"></div>
			<div class="grid-item"></div>
			<div class="grid-item"></div>
			<div class="grid-item md-none"></div>
			<div class="grid-item md-none"></div>
			<div class="grid-item md-none"></div>
		</div>
		<div class="band-imgWrapper">
			<img :src="BannerLogo" class="bannerImage-imagineTech"/>
		</div>
		</v-flex>
		<v-flex id="about" class="page-title">
				<v-flex xs12 sm12 md10 lg10 xl8  class="section-title">
					<span class="text-appear" style="margin-right:10px;display:inline-block">Award-winning.</span>
					<span class="text-appear" style="margin-right:10px;display:inline-block">Credible.</span>
					<span class="text-appear" style="margin-right:10px;display:inline-block">Designed</span>
					<span class="text-appear" style="margin-right:10px;display:inline-block">and</span>
					<span class="text-appear" style="margin-right:10px;display:inline-block">deployed</span>
					<span class="text-appear" style="margin-right:10px;display:inline-block">for</span>
					<span class="text-appear" style="margin-right:10px;display:inline-block">enterprise</span>
					<span class="text-appear" style="margin-right:10px;display:inline-block">use.</span>
				</v-flex>
		</v-flex>
		<v-flex class="my-3">
			<v-divider class="divider"></v-divider>
		</v-flex>
		<v-flex style="padding: 40px 40px 40px 0">
			<v-flex xs12 sm12 md10 lg11 xl8 class="award-winning-content">
					We are a wholly owned subsidiary of ValueLabs, a global technology company, with <span style="fontWeight: 700; font-family:JostMedium; background:#EDD46F;">300 clients</span>,
					<span style="fontWeight: 700; font-family: Jost; background:#EDD46F;">7000 employees</span> and <span style="fontWeight: 700; font-family:JostMedium; background:#EDD46F;">28 locations</span> worldwide.
				</v-flex>
		</v-flex>
		<v-flex mb-16 style="padding:30px 0 50px">
			<v-flex xs12 sm12 md10 lg10 xl8 class="award-winning-content">
					Backed by <span style="fontWeight: 700; font-family: Jost; background:#EDD46F;">26 years</span> of experience in Design, Tech, and Business innovation, our unique OneCompany® model of engagement enables us to engage with clients as their exclusive extended
					team.We design, build, and operate business solutions for our clients leveraging our <span style="fontWeight: 700; font-family:JostMedium; background:#EDD46F;">solution suites.</span>
				</v-flex>
		</v-flex>
		<v-flex class="page-title" mt-16>
				<v-flex xs12 sm12 md10 lg10 xl8 class="section-title" style="font-family: JostMedium;">
					<span class="text-appear" style="margin-right:10px;display:inline-block">Our</span>
					<span class="text-appear" style="margin-right:10px;display:inline-block">solutions</span>
					<span class="text-appear" style="margin-right:10px;display:inline-block">are</span>
					<span class="text-appear" style="margin-right:10px;display:inline-block">designed</span>
					<span class="text-appear" style="margin-right:10px;display:inline-block">for</span>
					<span class="text-appear" style="margin-right:10px;display:inline-block">enterprises</span>
					<span class="text-appear" style="margin-right:10px;display:inline-block">to</span>
				</v-flex>
		</v-flex>
		<v-flex class="my-3">
			<v-divider class="divider"></v-divider>
		</v-flex>
		<template>
			<v-container class="bg-surface-variant" mb-16>
				<v-row no-gutters>
					<v-col>
						<v-sheet class="ma-4" style="background: #f5f5f5; width: 310px;"> 
							<div style="height:223px;"><img :src="image" class="img-solutions"/></div>
							<v-sheet class="my-2" style="fontWeight: 600; padding-right: 46px;fontSize: 18px; lineHeight: 160%; font-family: Jost;background:#f5f5f5;">
								<span style="borderBottom:2px solid #EDD46F; font-family: Jost;" class="py-2">Drive Enhanced Productivity</span>
							</v-sheet>
						</v-sheet>
					</v-col>
					<v-col>
						<v-sheet class="ma-4" style="background:#f5f5f5;width: 310px;"> 
							<div style="height:223px;"><img :src="bottomLines" class="img-solutions"/></div>
							<v-sheet class="my-2" style="fontWeight: 600; fontSize: 18px;padding-right: 46px; lineHeight: 160%; font-family: Jost;background:#f5f5f5;">
								<span style="borderBottom:2px solid #EDD46F; font-family: Jost;" class="py-2">Increase Bottom Line</span>
							</v-sheet>
						</v-sheet>
					</v-col>
					<v-col>
						<v-sheet class="ma-4" style="background:#f5f5f5;width: 310px;"> 
							<div style="height:223px;"><img :src="elevatedExperiences" class="img-solutions"/></div>
							<v-sheet class="py-2 stakeholders-card-wrapper">
							Elevate experiences for key stakeholders
							</v-sheet>
						</v-sheet>
					</v-col>
				</v-row>
			</v-container>
		</template>
		<v-flex mt-16 class="page-title">
			<v-flex xs12 sm12 md10 lg10 xl8 class="section-title" style="font-family:JostMedium;">
				<span class="text-appear" style="margin-right:10px;font-family: JostMedium;display:inline-block">Comprehensive</span>
				<span class="text-appear" style="margin-right:10px;font-family: JostMedium;display:inline-block">Solutions</span>
			</v-flex>
		</v-flex>
		<v-flex class="my-3">
			<v-divider class="divider"></v-divider>
		</v-flex>
		<v-flex style="padding:20px 0" mb-16>
			<v-flex xs12 class="imagine-comprehensive-txt">
				Our range of solutions can be rapidly deployed out-of-the-box or customized for specific enterprise requirements and business needs.
			</v-flex>
			<v-flex :style="{'padding':$vuetify.breakpoint.smAndUp?'60px 0':'40px 0'}">
				<v-layout row wrap justify-left align-center>
					<v-flex xs12 sm6 md6>
						<v-layout column>
							<v-flex class="landing-plus-point">
								<img :src="design" class="mr-3 mt-1" style="background:#EDD46F;"/>
								<span class="comprehensive-solution-features">Design</span>
							</v-flex>
							<v-flex class="landing-plus-point">
								<img :src="product" class="mr-3 mt-1" style="background:#EDD46F;" />
								<span class="comprehensive-solution-features">Product Development</span>
							</v-flex>
							<v-flex class="landing-plus-point">
								<img :src="quality" class="mr-3 mt-1" style="background:#EDD46F;" />
								<span class="comprehensive-solution-features">Quality Engineering</span>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex xs12 sm6 md6>
						<v-layout column>
							<v-flex class="landing-plus-point">
								<img :src="analytics" class="mr-3 mt-1" style="background:#EDD46F;" />
								<span class="comprehensive-solution-features">Data & Analytics</span>
							</v-flex>
							<v-flex class="landing-plus-point">
								<img :src="cybersecurity" class="mr-3 mt-1" style="background:#EDD46F;" />
								<span class="comprehensive-solution-features">Cybersecurity</span>
							</v-flex>
							<v-flex class="landing-plus-point">
								<img :src="supportLogo" class="mr-3 mt-1" style="background:#EDD46F;" />
								<span class="comprehensive-solution-features">Support</span>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-flex>
		<v-flex mt-16 class="page-title">
			<v-flex xs12 sm12 md10 lg10 xl8 class="section-title" style="font-family:JostMedium;">
				<span class="text-appear" style="margin-right:10px;font-family: JostMedium;display:inline-block">Solutions that enable you to be future-ready</span>
			</v-flex>
		</v-flex>
		<v-flex class="my-3">
			<v-divider class="divider"></v-divider>
		</v-flex>
		<div class="mb-6 mt-6 solution-card" id="solutions">
			<div class="container feature-brandCards">
				<div class="ma-4 feature-proof-brandCards">
					<div class="main-div solutions-main-wrapper">
						<div class="feature-card-header">
							<img :src="aideCx" class="brand-images" />
						</div>
						<div class="feature-card-bodyTxt">
							Boost your conversion rates and revenue by leveraging hyper-personalized user experiences driven by advanced conversational AI solutions.
						</div>
						<div class="view-solutions-txt">View solutions <span class="btnarrow"></span></div>
						<div v-on:click="isHidden = false" class="seeMore-btn">View solutions</div>
					</div>
					<div class="hover-div hover-div-wrapper">
						<div class="feature-card-header">
							<img :src="aideCx" class="brand-images" />
						</div>
						<div>
							<div class="mt-4 hover-content-wrapper">
								<a href="https://www.aideplatform.com/solutions/aide-cx/aide-chat.html" target="_blank" rel="noopener noreferrer"><img :src="aideChat" class="sub-brand-image" /></a>
								<div class="hover-txt-wrapper">Conversational AI Text and Voice Assistant</div>
							</div>
							<div class="hover-content-wrapper">
								<a href="https://www.aideplatform.com/solutions/aide-cx/aide-xperience.html" target="_blank" rel="noopener noreferrer"><img :src="aideExp" class="sub-brand-image" /></a>
								<div class="hover-txt-wrapper">Conversational Web and Mobile Experiences</div>
							</div>
							<div class="imagine-solutions-btn">
								<a href="https://www.aideplatform.com/solutions/aide-cx/" target="_blank" rel="noopener noreferrer" class="load-more-btn">Learn more</a>
							</div>
						</div>
					</div>
					<div class="hidden-wrapper" v-if="!isHidden">
						<div class="feature-card-header">
							<img :src="aideCx" class="brand-images" />
						</div>
						<div>
							<div class="mt-4 hover-content-wrapper">
								<a href="https://www.aideplatform.com/solutions/aide-cx/aide-chat.html" target="_blank" rel="noopener noreferrer"><img :src="aideChat" class="sub-brand-image" /></a>
								<div class="hover-txt-wrapper">Conversational AI Text and Voice Assistant</div>
							</div>
							<div class="hover-content-wrapper">
								<a href="https://www.aideplatform.com/solutions/aide-cx/aide-xperience.html" target="_blank" rel="noopener noreferrer"><img :src="aideExp" class="sub-brand-image" /></a>
								<div class="hover-txt-wrapper">Conversational Web and Mobile Experiences</div>
							</div>
							<div class="imagine-solutions-btn">
								<a href="https://www.aideplatform.com/solutions/aide-cx/" target="_blank" rel="noopener noreferrer" class="load-more-btn">Learn more</a>
							</div>
							<div v-on:click="isHidden = true" class="seeLess-btn">See less</div>
						</div>
					</div>
				</div>
				<div class="ma-4 feature-proof-brandCards">
					<div class="main-div solutions-main-wrapper">
						<div class="feature-card-header">
							<img :src="aideQe" class="brand-images" />
						</div>
						<div class="feature-card-bodyTxt">
							Empower your team with our QE solutions - AiDE API & AiDE Data Hub and streamline testing processes with unique features.
						</div>
						<div class="view-solutions-txt">View solutions <span class="btnarrow"></span></div>
						<div v-on:click="isBoltHidden = false" class="seeMore-btn">View solutions</div>
					</div>
					<div class="hover-div hover-div-wrapper">
						<div class="feature-card-header">
								<img :src="aideQe" class="brand-images" />
							</div>
							<div>
								<div class="mt-4 hover-content-wrapper">
									<a href="https://www.aideplatform.com/solutions/aide-qe/aide-api.html" target="_blank" rel="noopener noreferrer"><img :src="aideAPI" class="sub-brand-image" /></a>
									<div class="hover-txt-bolt-origin">Revolutionize API Testing</div> 
								</div>
								<div class="hover-content-wrapper">
									<a href="https://www.aideplatform.com/solutions/aide-qe/aide-data-hub.html" target="_blank" rel="noopener noreferrer"><img :src="aideDataHub" class="aideDataHub-img" /></a>
									<div class="hover-txt-bolt-origin">Generate Data Securely on Demand</div>
									<div class="imagine-solutions-btn">
										<a href="https://www.aideplatform.com/solutions/aide-qe/" target="_blank" rel="noopener noreferrer" class="load-more-btn">Learn more</a>
									</div>
								</div>
							</div>
					</div>
					<div class="hidden-wrapper" v-if="!isBoltHidden">
						<div class="feature-card-header">
								<img :src="aideQe" class="brand-images" />
							</div>
							<div>
								<div class="mt-4 hover-content-wrapper">
									<a href="https://www.aideplatform.com/solutions/aide-qe/aide-api.html" target="_blank" rel="noopener noreferrer"><img :src="aideAPI" class="sub-brand-image" /></a>
									<div class="hover-txt-bolt-origin">Revolutionize API Testing</div> 
								</div>
								<div class="hover-content-wrapper">
									<a href="https://www.aideplatform.com/solutions/aide-qe/aide-data-hub.html" target="_blank" rel="noopener noreferrer"><img :src="aideDataHub" class="aideDataHub-img" /></a>
									<div class="hover-txt-bolt-origin">Generate Data Securely on Demand</div>
								</div>
								<div class="imagine-solutions-btn">
									<a href="https://www.aideplatform.com/solutions/aide-qe/" target="_blank" rel="noopener noreferrer" class="load-more-btn">Learn more</a>
								</div>
							</div>
							<div v-on:click="isBoltHidden = true" class="seeLess-btn">See less</div>
					</div>
				</div>
				<div class="ma-4 feature-proof-brandCards">
					<div class="main-div solutions-main-wrapper">
						<div class="feature-card-header">
							<img :src="aideAnalytics" class="brand-images" />
						</div>
						<div class="feature-card-bodyTxt">
							Comprehensive Data & Analytics solutions that enable data democratization and seamless adoption of data initiatives.
						</div>
						<div class="view-solutions-txt">View solutions <span class="btnarrow"></span></div>
						<div v-on:click="isOrionHidden = false" class="seeMore-btn">View solutions</div>
					</div>
					<div class="hover-div hover-div-wrapper">
						<div>
							<div class="feature-card-header">
							<img :src="aideAnalytics" class="brand-images" />
						</div>
						<div>
							<div class="mt-4 hover-content-wrapper">
								<a href="https://www.aideplatform.com/solutions/aide-analytics/aide-vault.html" target="_blank" rel="noopener noreferrer"><img :src="orionVault" class="sub-brand-image" /></a>
								<div class="hover-txt-bolt-origin">Simple and Efficient Reporting</div>
							</div>
							<div class="hover-content-wrapper">
								<a href="https://www.aideplatform.com/solutions/aide-analytics/aide-bi.html" target="_blank" rel="noopener noreferrer"><img :src="orionBI" class="aideBI-image" /></a>
								<div class="hover-txt-bolt-origin">All Things Data, At One Place</div>
							</div>
							<div class="imagine-solutions-btn">
								<a href="https://www.aideplatform.com/solutions/aide-analytics/" target="_blank" rel="noopener noreferrer" class="load-more-btn">Learn more</a>
							</div>
						</div>
						</div>
					</div>
					<div class="hidden-wrapper" v-if="!isOrionHidden">
						<div>
							<div class="feature-card-header">
							<img :src="aideAnalytics" class="brand-images" />
						</div>
						<div>
							<div class="mt-4 hover-content-wrapper">
								<a href="https://www.aideplatform.com/solutions/aide-analytics/aide-vault.html" target="_blank" rel="noopener noreferrer"><img :src="orionVault" class="sub-brand-image" /></a>
								<div class="hover-txt-bolt-origin">Simple and Efficient Reporting</div>
							</div>
							<div class="hover-content-wrapper">
							<a href="https://www.aideplatform.com/solutions/aide-analytics/aide-bi.html" target="_blank" rel="noopener noreferrer"><img :src="orionBI" class="aideBI-image" /></a>
								<div class="hover-txt-bolt-origin">All Things Data, At One Place</div>
							</div>
						</div>
						<div class="imagine-solutions-btn">
								<a href="https://www.aideplatform.com/solutions/aide-analytics/" target="_blank" rel="noopener noreferrer" class="load-more-btn">Learn more</a>
							</div>
						</div>
						<div v-on:click="isOrionHidden = true" class="seeLess-btn">See less</div>
					</div>
				</div>
				<div class="ma-4 feature-proof-brandCards">
					<div class="main-div solutions-main-wrapper">
						<div class="feature-card-header">
							<img :src="aideSecurity" class="brand-images" />
						</div>
						<div class="feature-card-bodyTxt">
							Generative AI cybersecurity solutions that empower your workforce and defend against potential threats.
						</div>
						<div class="view-solutions-txt">View solutions <span class="btnarrow"></span></div>
						<div v-on:click="isAideSecurity = false" class="seeMore-btn">View solutions</div>
					</div>
					<div class="hover-div hover-div-wrapper">
						<div class="feature-card-header">
								<img :src="aideSecurity" class="brand-images" />
							</div>
							<div>
								<div class="mt-4 hover-content-wrapper">
									<a href="https://www.aideplatform.com/solutions/aide-security/aide-guard.html" target="_blank" rel="noopener noreferrer"><img :src="aideGuard" class="sub-brand-image" /></a>
									<div class="hover-txt-bolt-origin">XDR Tool for Real-time Threat Detection</div> 
								</div>
								<div class="hover-content-wrapper">
									<a href="https://www.aideplatform.com/solutions/aide-security/aide-phish.html" target="_blank" rel="noopener noreferrer"><img :src="aidePhish" class="sub-brand-image" /></a>
									<div class="hover-txt-bolt-origin">Enhance your Employees’ Cybersecurity Awareness</div>
								</div>
								<div class="imagine-solutions-btn">
								<a href="https://www.aideplatform.com/solutions/aide-security/" target="_blank" rel="noopener noreferrer" class="load-more-btn">Learn more</a>
							</div>
							</div>
					</div>
					<div class="hidden-wrapper" v-if="!isAideSecurity">
						<div class="feature-card-header">
								<img :src="aideSecurity" class="brand-images" />
							</div>
							<div>
								<div class="mt-4 hover-content-wrapper">
									<a href="https://www.aideplatform.com/solutions/aide-security/aide-guard.html" target="_blank" rel="noopener noreferrer"><img :src="aideGuard" class="sub-brand-image" /></a>
									<div class="hover-txt-bolt-origin">XDR Tool for Real-time Threat Detection</div> 
								</div>
								<div class="hover-content-wrapper">
									<a href="https://www.aideplatform.com/solutions/aide-security/aide-phish.html" target="_blank" rel="noopener noreferrer"><img :src="aidePhish" class="sub-brand-image" /></a>
									<div class="hover-txt-bolt-origin">Enhance your Employees’ Cybersecurity Awareness</div>
								</div>
								<div class="imagine-solutions-btn">
								<a href="https://www.aideplatform.com/solutions/aide-security/" target="_blank" rel="noopener noreferrer" class="load-more-btn">Learn more</a>
							</div>
							</div>
							<div v-on:click="isAideSecurity = true" class="seeLess-btn">See less</div>
					</div>
				</div>
			</div>
			<div class="text-center mt-16 mb-16">
				<div class="goto-btn" outlined>
					<a href="https://www.aideplatform.com/solutions/" target="_blank" rel="noopener noreferrer">Go to Solutions Page <img :src="link" class="px-2" /></a>
				</div>
			</div>
		</div>
		<!--<v-flex style="padding:90px 0">
			<v-layout column id="landing-business-evolution">
				<v-flex style="text-align:center;font-family:JostBold;font-size:30px;margin-bottom:50px">
					Channels that drive growth
				</v-flex>
				<v-flex style="padding:20px 0;position:relative" v-if="$vuetify.breakpoint.smAndUp">
					<div class="landing-evolution-connector"></div>
					<v-layout row wrap style="position:relative">
						<v-flex sm3 md3>
							<div class="black" style="height:30px;width:30px;border-radius:50%;display:flex;justify-content:center;align-items:center;margin:0 auto;">
								<v-icon color="accent">mdi-arrow-up</v-icon>
							</div>
						</v-flex>
						<v-flex sm3 md3>
							<div class="black" style="height:30px;width:30px;border-radius:50%;display:flex;justify-content:center;align-items:center;margin:0 auto;">
								<v-icon color="accent">mdi-arrow-up</v-icon>
							</div>
						</v-flex>
						<v-flex sm3 md3>
							<div class="black" style="height:30px;width:30px;border-radius:50%;display:flex;justify-content:center;align-items:center;margin:0 auto;">
								<v-icon color="accent">mdi-arrow-up</v-icon>
							</div>
						</v-flex>
						<v-flex sm3 md3>
							<div class="accent" style="height:30px;width:30px;border-radius:50%;display:flex;justify-content:center;align-items:center;margin:0 auto;">
								<v-icon color="black">mdi-crown</v-icon>
							</div>
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex>
					<v-layout row wrap>
						<v-flex xs12 sm3 md3 style="padding:10px;" class="landing-evolution-section">
							<v-layout column :style="{ 'border-right':$vuetify.breakpoint.smAndUp?'1px solid rgba(0,0,0,0.12)':'none', 'border-bottom':$vuetify.breakpoint.xsOnly?'1px solid rgba(0,0,0,0.12)':'none', 'padding': $vuetify.breakpoint.smOnly?'0':'10px' }">
								<v-flex v-if="$vuetify.breakpoint.xsOnly" mb-5>
									<div class="black" style="height:30px;width:30px;border-radius:50%;display:flex;justify-content:center;align-items:center;margin:0 auto;">
										<v-icon color="accent">mdi-arrow-up</v-icon>
									</div>
								</v-flex>
								<v-flex style="text-align:center;font-family:JostBold;font-size:16px;margin-bottom:50px;">
									EMPLOYEES
								</v-flex>
								<v-flex>
									<v-layout column>
										<v-flex class="landing-plus-point" style="font-size:18px;">
											<div class="landing-plus-icon-container">
												<div class="landing-plus-icon"></div>
											</div>
											Build technology and serve customers
										</v-flex>
										<v-flex class="landing-plus-point" style="font-size:18px;">
											<div class="landing-plus-icon-container">
												<div class="landing-plus-icon"></div>
											</div>
											Custodians of business growth
										</v-flex>
										<v-flex class="landing-plus-point" style="font-size:18px;">
											<div class="landing-plus-icon-container">
												<div class="landing-minus-icon"></div>
											</div>
											But have limited ability to scale
										</v-flex>
									</v-layout>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 sm3 md3 style="padding:10px;animation-delay:0.4s" class="landing-evolution-section">
							<v-layout column :style="{ 'border-right':$vuetify.breakpoint.smAndUp?'1px solid rgba(0,0,0,0.12)':'none', 'border-bottom':$vuetify.breakpoint.xsOnly?'1px solid rgba(0,0,0,0.12)':'none', 'padding': $vuetify.breakpoint.smOnly?'0':'10px' }">
								<v-flex v-if="$vuetify.breakpoint.xsOnly" mb-5>
									<div class="black" style="height:30px;width:30px;border-radius:50%;display:flex;justify-content:center;align-items:center;margin:0 auto;">
										<v-icon color="accent">mdi-arrow-up</v-icon>
									</div>
								</v-flex>
								<v-flex style="text-align:center;font-family:JostBold;font-size:16px;margin-bottom:50px">
									PARTNERS
								</v-flex>
								<v-flex>
									<v-layout column>
										<v-flex class="landing-plus-point" style="font-size:18px;">
											<div class="landing-plus-icon-container">
												<div class="landing-plus-icon"></div>
											</div>
											Flexibility to scale at will
										</v-flex>
										<v-flex class="landing-plus-point" style="font-size:18px;">
											<div class="landing-plus-icon-container">
												<div class="landing-plus-icon"></div>
											</div>
											Access to diverse talent
										</v-flex>
										<v-flex class="landing-plus-point" style="font-size:18px;">
											<div class="landing-plus-icon-container">
												<div class="landing-minus-icon"></div>
											</div>
											But run the risk of divided loyalties
										</v-flex>
									</v-layout>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 sm3 md3 style="padding:10px;animation-delay:0.8s" class="landing-evolution-section">
							<v-layout column :style="{ 'border-right':$vuetify.breakpoint.smAndUp?'1px solid rgba(0,0,0,0.12)':'none', 'border-bottom':$vuetify.breakpoint.xsOnly?'1px solid rgba(0,0,0,0.12)':'none', 'padding': $vuetify.breakpoint.smOnly?'0':'10px' }">
								<v-flex v-if="$vuetify.breakpoint.xsOnly" mb-5>
									<div class="black" style="height:30px;width:30px;border-radius:50%;display:flex;justify-content:center;align-items:center;margin:0 auto;">
										<v-icon color="accent">mdi-arrow-up</v-icon>
									</div>
								</v-flex>
								<v-flex style="text-align:center;font-family:JostBold;font-size:16px;margin-bottom:50px">
									ACQUISITIONS
								</v-flex>
								<v-flex>
									<v-layout column>
										<v-flex class="landing-plus-point" style="font-size:18px;">
											<div class="landing-plus-icon-container">
												<div class="landing-plus-icon"></div>
											</div>
											Provide access to tech, customers and revenue
										</v-flex>
										<v-flex class="landing-plus-point" style="font-size:18px;">
											<div class="landing-plus-icon-container">
												<div class="landing-plus-icon"></div>
											</div>
											Business models validated at scale
										</v-flex>
										<v-flex class="landing-plus-point" style="font-size:18px;">
											<div class="landing-plus-icon-container">
												<div class="landing-minus-icon"></div>
											</div>
											But rarely are a perfect match and lead to overheads
										</v-flex>
									</v-layout>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 sm3 md3 style="padding:10px;animation-delay:1.2s" class="landing-evolution-section">
							<v-layout column :style="{'padding': $vuetify.breakpoint.smOnly?'0':'10px'}">
								<v-flex v-if="$vuetify.breakpoint.xsOnly" mb-5>
									<div class="accent" style="height:30px;width:30px;border-radius:50%;display:flex;justify-content:center;align-items:center;margin:0 auto;">
										<v-icon color="black">mdi-crown</v-icon>
									</div>
								</v-flex>
								<v-flex style="text-align:center;font-family:JostBold;font-size:30px;line-height:24px;color:#DFC24E;margin-bottom:50px;">
									IMAGINE
								</v-flex>
								<v-flex>
									<v-layout column>
										<v-flex class="landing-plus-point" style="font-size:18px;">
											<div class="landing-plus-icon-container">
												<div class="landing-plus-icon"></div>
											</div>
											Spirit of a captive
										</v-flex>
										<v-flex class="landing-plus-point" style="font-size:18px;">
											<div class="landing-plus-icon-container">
												<div class="landing-plus-icon"></div>
											</div>
											Benefits of an outsourced operation
										</v-flex>
										<v-flex class="landing-plus-point" style="font-size:18px;">
											<div class="landing-plus-icon-container">
												<div class="landing-plus-icon"></div>
											</div>
											Tech and revenue streams that are custom built and managed by us. With the risk borne by us. And an option for you to acquire
										</v-flex>
									</v-layout>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-flex>
		<v-flex style="padding:70px 0">
			<v-layout row wrap justify-center align-center>
				<v-flex xs12 sm6 md5 :style="{'font-size':$vuetify.breakpoint.smAndUp?'26px':'20px'}" id="landing-funding-text">
					<span style="font-family:JostBold">Imagine</span> is the first of its kind model of engagement. Powered by Smart Equity. A combination of technology, financial and human capital - where Private Equity meets Digital Enablement.
				</v-flex>
				<v-flex xs12 sm6 md5 offset-md1 :style="{'margin-top':$vuetify.breakpoint.smAndUp?'0px':'50px'}" id="landing-funding-value">
					<v-layout column>
						<v-flex>
							<div style="border-radius:50%;display:flex;justify-content:center;align-items:center;text-align:center;font-family:JostBold;position:relative;left:50%;transform:translateX(-50%) scale(0);transition:0.5s ease-in-out" class="landing-funding-value-circle-big accent--text black" :style="{'height':$vuetify.breakpoint.mdAndUp?'300px':'200px', 'width':$vuetify.breakpoint.mdAndUp?'300px':'200px', 'font-size':$vuetify.breakpoint.mdAndUp?'70px':'50px'}">
								$50M
								<div style="border-radius:50%;display:flex;justify-content:center;align-items:center;text-align:center;font-family:JostMedium;position:absolute;top:0;right:0;transform:translate(30%, -30%) scale(0);transition:0.5s ease-in-out" class="black--text accent landing-funding-value-circle-small" :style="{'height':$vuetify.breakpoint.mdAndUp?'150px':'75px', 'width':$vuetify.breakpoint.mdAndUp?'150px':'75px', 'font-size':$vuetify.breakpoint.mdAndUp?'20px':'14px'}">
									Seed<br />funding
								</div>
							</div>
						</v-flex>
						<v-flex style="text-align:center;font-size:34px;font-family:JostBold" mt-5>
							Set aside for<br />
							<span>Imagine</span> projects
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-flex>
		<v-flex :style="{'padding':$vuetify.breakpoint.smAndUp?'60px 0':'40px 0'}">
			<v-layout row wrap justify-center align-center>
				<v-flex xs12 sm6 md5 v-if="$vuetify.breakpoint.smAndUp">
					<v-layout column>
						<v-flex class="landing-plus-point" :style="{'font-size':$vuetify.breakpoint.smAndUp?'20px':'16px'}">
							<v-icon color="accent" class="black mr-3" style="border-radius:50%">mdi-plus</v-icon>
							Risk of the build out on us
						</v-flex>
						<v-flex class="landing-plus-point" :style="{'font-size':$vuetify.breakpoint.smAndUp?'20px':'16px'}">
							<v-icon color="accent" class="black mr-3" style="border-radius:50%">mdi-plus</v-icon>
							Accelerate your roadmap and seize every opportunity that can augment your business
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex xs12 sm6 md5 offset-md1 :style="{'font-size':$vuetify.breakpoint.smAndUp?'26px':'20px'}">
					The process of converting an idea to revenue is fraught with risk and takes large investments, with extended timelines. We invest in the idea, develop the tech, and run the operations.
				</v-flex>
				<v-flex xs12 sm6 md5 v-if="$vuetify.breakpoint.xsOnly">
					<v-layout column>
						<v-flex class="landing-plus-point" :style="{'font-size':$vuetify.breakpoint.smAndUp?'20px':'16px', 'min-height':$vuetify.breakpoint.smAndUp?'50px':'20px'}">
							<v-icon small color="accent" class="black mr-3" style="border-radius:50%;margin-top:3px;">mdi-plus</v-icon>
							Risk of the build out on us
						</v-flex>
						<v-flex class="landing-plus-point" :style="{'font-size':$vuetify.breakpoint.smAndUp?'20px':'16px', 'min-height':$vuetify.breakpoint.smAndUp?'50px':'20px'}">
							<v-icon small color="accent" class="black mr-3" style="border-radius:50%;margin-top:3px;">mdi-plus</v-icon>
							Accelerate your roadmap and seize every opportunity that can augment your business
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-flex>
		<v-flex :style="{'padding':$vuetify.breakpoint.smAndUp?'60px 0':'40px 0'}">
			<v-layout row wrap justify-center align-center>
				<v-flex xs12 sm6 md5 :style="{'font-size':$vuetify.breakpoint.smAndUp?'26px':'20px'}">
					We leverage our Imagine Tech to improve efficiency on all three dimensions: quality, time, cost
				</v-flex>
				<v-flex xs12 sm6 md5 offset-md1>
					<v-layout column>
						<v-flex class="landing-plus-point" :style="{'font-size':$vuetify.breakpoint.smAndUp?'20px':'16px', 'min-height':$vuetify.breakpoint.smAndUp?'50px':'20px'}">
							<v-icon :small="$vuetify.breakpoint.xsOnly" color="accent" class="black mr-3" style="border-radius:50%" :style="{'margin-top':$vuetify.breakpoint.xsOnly?'3px':''}">mdi-plus</v-icon>
							Realize your vision within 90 days
						</v-flex>
						<v-flex class="landing-plus-point" :style="{'font-size':$vuetify.breakpoint.smAndUp?'20px':'16px', 'min-height':$vuetify.breakpoint.smAndUp?'50px':'20px'}">
							<v-icon :small="$vuetify.breakpoint.xsOnly" color="accent" class="black mr-3" style="border-radius:50%" :style="{'margin-top':$vuetify.breakpoint.xsOnly?'3px':''}">mdi-plus</v-icon>
							With efficiency gains to the tune of 40% and beyond
						</v-flex>
						<v-flex class="landing-plus-point" :style="{'font-size':$vuetify.breakpoint.smAndUp?'20px':'16px', 'min-height':$vuetify.breakpoint.smAndUp?'50px':'20px'}">
							<v-icon :small="$vuetify.breakpoint.xsOnly" color="accent" class="black mr-3" style="border-radius:50%" :style="{'margin-top':$vuetify.breakpoint.xsOnly?'3px':''}">mdi-plus</v-icon>
							<router-link to="/solutions" style="text-decoration:none" class="black--text">Explore Imagine Tech</router-link>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-flex>
		<v-flex :style="{'padding':$vuetify.breakpoint.smAndUp?'60px 0':'40px 0'}" id="landing-case-study-container">
			<v-layout row wrap justify-center>
				<v-flex xs12 sm4 md4 class="landing-case-study-cards">
					<v-layout column>
						<v-flex class="landing-case-study-cards-index">
							01
						</v-flex>
						<v-flex class="landing-case-study-cards-body">
							Through this model, we enabled one of our clients in the financial services space to custom-build tech that solves their use case instead of acquiring a company worth <span style="color:#DFC24E;font-family:JostBold">$1B</span>. Potentially reducing their processing time and improving quality by over <span style="color:#DFC24E;font-family:JostBold">40%</span>. Curious to see how? <button class="contact-us-scroll" @click="contactUsScroll">Contact Us!</button>.
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex xs12 sm4 md4 class="landing-case-study-cards" style="animation-delay:0.4s" :style="{ 'border-right':$vuetify.breakpoint.smAndUp?'1px solid rgba(0,0,0,0.12)':'none', 'border-left':$vuetify.breakpoint.smAndUp?'1px solid rgba(0,0,0,0.12)':'none', 'border-bottom':$vuetify.breakpoint.xsOnly?'1px solid rgba(0,0,0,0.12)':'none', 'border-top':$vuetify.breakpoint.xsOnly?'1px solid rgba(0,0,0,0.12)':'none'}">
					<v-layout column>
						<v-flex class="landing-case-study-cards-index">
							02
						</v-flex>
						<v-flex class="landing-case-study-cards-body">
							We helped a leading market research company save over <span style="color:#DFC24E;font-family:JostBold">$2M</span> per year in just <span style="color:#DFC24E;font-family:JostBold">60 days</span>. Our technology is changing the face of the market research industry through predictive product roadmap recommendations. Want to understand how we are enabling the market research world to shift left? <button class="contact-us-scroll" @click="contactUsScroll">Contact Us!</button>.
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex xs12 sm4 md4 class="landing-case-study-cards" style="animation-delay:0.8s" >
					<v-layout column>
						<v-flex class="landing-case-study-cards-index">
							03
						</v-flex>
						<v-flex class="landing-case-study-cards-body">
							We helped a health and safety company serve <span style="color:#DFC24E;font-family:JostBold">8x</span> the number of customer queries with <span style="color:#DFC24E;font-family:JostBold">zero</span> increase in human support agents. Customers could get responses to complex queries through cognitive assistants with no human intervention. Want to scale your operations efficiently? <button class="contact-us-scroll" @click="contactUsScroll">Contact Us!</button>.
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-flex>
		<v-flex :style="{'padding':$vuetify.breakpoint.smAndUp?'60px 0':'40px 0'}">
			<v-layout row wrap>
				<v-flex xs12 sm10 md8 lg8 xl6  :style="{'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px'}">
					We enable clients to <span style="font-family:JostBold">'Imagine'</span> today and realize value within 90 days. We employ the Lean Startup approach, with Customer Development, Agile Product Development, leading to an <span style="color:#DFC24E;font-family:JostBold">MVP</span>.
				</v-flex>
			</v-layout>
		</v-flex>
		<v-flex>
			<v-layout row wrap>
				<v-flex xs12 sm10 md8 lg8 xl6 style="font-family:JostMedium" :style="{'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px'}">
					<v-layout column>
						<v-flex>
							Our outcome based pricing structure ensures you have <span style="color:#DFC24E;font-family:JostBold">zero risk</span>.
						</v-flex>
						<v-flex mt-5>
							If the idea doesn't work, the entire risk is ours. If it works, we succeed at <span style="color:#DFC24E;font-family:JostBold">creating value together</span>.
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-flex>-->
		<v-flex class="page-title" pt-16 mt-16>
		<span id="contact" class="contact-us-wrapper" style="font-family:JostMedium;">Curious about what Imagine could do for your business?</span>
		</v-flex>
		<v-flex>
			<v-flex>
				<ContactUs :interestedProducts="[]" />
			</v-flex>
		</v-flex>
	</v-layout>
</template>

<script>
import BannerLogo from '../../../assets/images/bannerLogo.svg'
import ContactUs from '../ContactUs'
import image from '../../../assets/images/drive-enhanced-productivity.jpg'
import bottomLines from '../../../assets/images/increased-Bottom-lines.jpg'
import elevatedExperiences from '../../../assets/images/elevated-experience-for-both-internal-employees-and-end-customers.jpg'
import design from '../../../assets/images/design-icon.png'
import product from '../../../assets/images/product-development-icon.png'
import quality from '../../../assets/images/quality-engineering-icon.png'
import analytics from '../../../assets/images/data-analytics-icon.png'
import cybersecurity from '../../../assets/images/cybersecurity-icon.png'
import link from '../../../assets/images/nav-link-icon.png'
import aideLogo from '../../../assets/images/aide-logo.png'
import boltLogo from '../../../assets/images/bolt-logo.png'
import orionLogo from '../../../assets/images/orion-logo.png'
import lumenLogo from '../../../assets/images/lumen-logo.png'
import supportLogo from '../../../assets/images/support-img.png'
import aideSdlc from '../../../assets/images/aide-sdlc.png'
import aideChat from '../../../assets/images/aide-chat-white-logo.svg'
import aideQe from '../../../assets/images/aide-qe-white-logo.svg'
import aideExp from '../../../assets/images/aide-xperience-white-logo.svg'
import aideAnalytics from '../../../assets/images/aide-analytics-white-logo.svg'
import aideSecurity from '../../../assets/images/aide-security-white-logo.svg'
import orionBI from '../../../assets/images/aide-bi-white-logo.svg'
import orionVault from '../../../assets/images/aide-vault-white-logo.svg'
import lumenGaurd from '../../../assets/images/lumenGaurd.png'
import lumenPhish from '../../../assets/images/lumenPhish.png'
import boltAPI from '../../../assets/images/boltAPI.png'
import boltDataHub from '../../../assets/images/boltDataHub.png'
import aideCx from '../../../assets/images/aide-cx-white-logo.svg'
import aideAPI from '../../../assets/images/aide-api-white-logo.svg'
import aideDataHub from '../../../assets/images/aide-data-hub-white-logo.svg'
import aideGuard from '../../../assets/images/aide-guard-white-logo.svg'
import aidePhish from '../../../assets/images/aide-phish-white-logo.svg'
export default {
	name: 'LandingPage',
	components: {
		//Logo,
		ContactUs
	},
	data() {
		return {
			openingLine: {
				actual: 'Solutions that guarantee $ a Competitive Edge. $ Powered by Generative AI.',
				computed: '',
				index: 0
			},
			isHidden: true,
			isOrionHidden:true,
			isBoltHidden:true,
			isAideSecurity:true,
			image: image,
			BannerLogo:BannerLogo,
			bottomLines: bottomLines,
			elevatedExperiences: elevatedExperiences,
			design:design,
			product: product,
			quality: quality,
			analytics: analytics,
			cybersecurity: cybersecurity,
			link:link,
			aideLogo:aideLogo,
			aideCx:aideCx,
			boltLogo:boltLogo,
			orionLogo:orionLogo,
			lumenLogo:lumenLogo,
			supportLogo:supportLogo,
			aideSdlc:aideSdlc,
			aideChat:aideChat,
			aideQe:aideQe,
			aideAPI:aideAPI,
			aideAnalytics:aideAnalytics,
			aideSecurity:aideSecurity,
			aideGuard:aideGuard,
			aidePhish:aidePhish,
			aideDataHub:aideDataHub,
			aideExp:aideExp,
			orionBI:orionBI,
			orionVault:orionVault,
			lumenGaurd:lumenGaurd,
			lumenPhish:lumenPhish,
			boltAPI:boltAPI,
			boltDataHub:boltDataHub
		}
	},
	beforeRouteLeave (to, from, next) {
		if(this.$store.getters.getFirstTimeLoadValue){
			this.$store.dispatch('loadingFinished')
		}
		next()
	},
	mounted() {
		document.addEventListener('scroll', this.handleScrollEvent)
		let interval = this.$store.getters.getFirstTimeLoadValue ? 3800 : 100
		setTimeout(() => {
			this.getTypedOpeningLine()
		}, interval)
	},
	beforeDestroy() {
		document.removeEventListener('scroll', this.handleScrollEvent)
	},
	methods: {
		contactUsScroll() {
			document.getElementById('contact-us-form').scrollIntoView({behavior: "smooth"})
		},
		getTypedOpeningLine() {
			if (this.openingLine.index < this.openingLine.actual.length) {
				const currentChar = this.openingLine.actual.charAt(this.openingLine.index)
				if(currentChar == '$'){
					this.openingLine.computed += '<br/>'
				} else{
				this.openingLine.computed += this.openingLine.actual.charAt(this.openingLine.index)
				}
				this.openingLine.index++
				setTimeout(() => {
					this.getTypedOpeningLine()
				}, 50)
			}
		},
		handleScrollEvent() {
			let evolutionTarget = document.getElementById('landing-business-evolution')
			if(evolutionTarget.getBoundingClientRect().top < (window.innerHeight*2/3)) {
				if(this.$vuetify.breakpoint.smAndUp) {
					document.getElementsByClassName('landing-evolution-connector')[0].classList.add('expand-line')
				}
				let sections = document.getElementsByClassName('landing-evolution-section')
				for(let i = 0; i < sections.length; i++) {
					let section = sections[i];
					section.classList.add('fade-in-animation')
				}
			}
			let fundingTextTarget = document.getElementById('landing-funding-text')
			if(fundingTextTarget.getBoundingClientRect().top < (window.innerHeight*2/3)) {
				fundingTextTarget.classList.add('fade-in-animation')
			}
			let fundingValueTarget = document.getElementById('landing-funding-value')
			if(fundingValueTarget.getBoundingClientRect().top < (window.innerHeight*2/3)) {
				let bigCircle = document.getElementsByClassName('landing-funding-value-circle-big')[0]
				bigCircle.style.transform = 'translateX(-50%) scale(1)'
				setTimeout(() => {
					let smallCircle = document.getElementsByClassName('landing-funding-value-circle-small')[0]
					smallCircle.style.transform = 'translate(30%, -30%) scale(1)'
				}, 500)
			}
			let caseStudyTarget = document.getElementById('landing-case-study-container')
			if(caseStudyTarget.getBoundingClientRect().top < (window.innerHeight*2/3)) {
				let cards = document.getElementsByClassName('landing-case-study-cards')
				for(let i = 0; i < cards.length; i++) {
					let card = cards[i];
					card.classList.add('fade-in-animation')
				}
			}
		}
	}
}
</script>

<style lang="css" scoped>
#landing-funding-text{
	opacity:0
}
.fade-in-animation{
	/* display:flex;
	flex-wrap: wrap;
	align-items:center;
	justify-content:flex-end; */
	animation:fade 1s ease-in-out;
	animation-fill-mode: forwards;
	opacity:0;
}
@keyframes fade {
	from{ opacity:0; }
	to{ opacity:1 }
}
.swipe-color{
	margin:0 15px;
	position:relative;
	overflow:hidden;
	animation: change-color 1s linear;
	animation-fill-mode: forwards;
}
@keyframes change-color {
	0%, 50%{
		color: #353839
	}
	51%, 100%{
		color: #DFC24E
	}
}
.scale-animation{
	animation:scale-it 1s ease-in-out;
	animation-fill-mode: forwards;
}
@keyframes scale-it {
	from{ transform:scale(0); }
	to{ transform:scale(1) }
}
.swipe-color-overlay{
	position:absolute;
	top:0;
	left:0;
	height:100%;
	width:100%;
	background: #DFC24E;
	animation:swipe 1s ease-in-out;
	animation-fill-mode: forwards;
	transform:translateX(-110%)
}
@keyframes swipe {
	0%{
		transform:translateX(-110%)
	}
	50%{
		transform:translateX(0%)
	}
	100%{
		transform:translateX(110%)
	}
}
.typing-animation{
	width:0;
	white-space: nowrap;
	overflow:hidden;
	animation: typing 3.6s steps(40, end);
	animation-fill-mode: forwards;
}
@keyframes typing {
	from { width: 0 }
	to { width: 100%;}
}
.landing-plus-point{
	display:flex;
	align-items:flex-start;
	margin:10px 0;
	min-height:50px;
}
.landing-plus-point a {
	text-decoration: underline!important
}
.landing-plus-point a:hover{
	color:#DFC24E!important
}
.landing-evolution-connector{
	position:absolute;
	top:50%;
	left:12.5%;
	height:4px;
	width:0;
	transition:1.6s ease-in-out;
	transform:translateY(-50%);
	background-image:linear-gradient(to right, #353839, #EDD46F)
}
.expand-line{
	width:75%;
}
.landing-evolution-section{
	opacity:0;
}
.landing-plus-icon-container{
	padding-right:10px;
	padding-top:3px;
}
.landing-plus-icon{
	height:20px;
	width:20px;
	border-radius:50%;
	background:#EDD46F;
	position:relative;
}
.landing-plus-icon::before{
	content:'';
	position:absolute;
	width:2px;
	height:12px;
	top:50%;
	left:50%;
	transform:translate(-50%, -50%);
	background:#353839
}
.landing-plus-icon::after{
	content:'';
	position:absolute;
	width:12px;
	height:2px;
	top:50%;
	left:50%;
	transform:translate(-50%, -50%);
	background:#353839
}
.landing-minus-icon{
	height:20px;
	width:20px;
	border-radius:50%;
	background:#353839;
	position:relative;
}
.landing-minus-icon::after{
	content:'';
	position:absolute;
	width:12px;
	height:2px;
	top:50%;
	left:50%;
	transform:translate(-50%, -50%);
	background:#EDD46F
}
.landing-scroll-bar{
	position:absolute;
	left:calc(50% - 3px);
	width:6px;
	background: rgba(53, 56, 57, 0.7);
	animation:fade 1s ease-in-out;
	animation-fill-mode: forwards;
	opacity:0;
	z-index:999;
}
.landing-scroll-ball{
	position: absolute;
	top:-10px;
	left:calc(50% - 10px);
	width:20px;
	height:20px;
	background:#EDD46F;
	border-radius: 50%;
	animation:scroll-ball 2s ease infinite;
}
@keyframes scroll-ball {
	0%{
		opacity:0;
		top:-10px;
	}
	10%{
		opacity:1;
		top:-10px;
	}
	90%{
		opacity:1;
		top:calc(100% - 10px);
	}
	100%{
		opacity:0;
		top:calc(100% - 10px);
	}
}
.contact-us-scroll{
	text-decoration:underline;
	outline:none;
	border:none
}
.contact-us-scroll:focus{
	outline:none;
}
.landing-case-study-cards{
	padding:40px;
	opacity:0;
}
.landing-case-study-cards-index{
	font-family: JostBold;
	font-size:30px;
	margin-bottom:20px
}
.feature-proof-brandCards{
	position: relative;
}
.hover-div {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
	cursor: pointer;
	background-color: rgba(26, 26, 26, 1);
	width: 100%;
    height: 100%;
	border-bottom: 2px solid var(--imagine-brand-color, #3D3D3D);
	border-radius:14px;
  }
  .feature-proof-brandCards:hover .hover-div {
    display: inline-block;
    opacity: 1;
  }
.card-brand-info{
	color: #000;
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
  }
  .card-info{
	color: #676767;
	font-family: Jost;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	text-align:left;
	padding: 0px 5px;
	margin-left: 50px;
	gap: 10px;
	align-self: stretch;
	border-radius: 30px;
	background: rgba(237, 212, 111, 0.09);
  }
  .solution-card{
	padding:20px 40px; 
	margin: 0 -20%;
  }
  @media only screen and (max-width: 1280px) {
    .solution-card{
		margin: 0;
	}
  }
 @media only screen and (max-width: 384px) {
	.img-solutions{
		width: 305px;
	}
	.stakeholders-card-wrapper{
		width: 305px !important;
	}
	.bannerImage-imagineTech{
		height: 54.797px !important;
	}
	.band-imgWrapper{
		margin: -220px auto !important;
	}
 }
 @media only screen and (max-width: 600px) {
	.feature-proof-brandCards{
		width: 100% !important;
	}
	.banner-image-imagine{
		margin: -220px 20%;
		position: absolute;
		height: 65.647px;
	}
	.bannerImage-imagineTech{
		height: 65.797px !important;
	}
	.band-imgWrapper{
		margin: -220px auto !important;
	}
	.md-none{
		display:none;
	}
	.origin-brandCard{
		margin:10px 0;
	}
	.goto-btn{
		width: 276px;
	}
	.openingLine-wrapper{
		font-size: 32px !important;
	}
	.animation-bar{
		height: 50vh !important;
	}
	.section-title,
	.solution-brands-title-section{
		font-size:22px !important;
	}
	.award-winning-content,
	.imagine-comprehensive-txt{
		font-size:16px !important;
	}
	.comprehensive-solution-features{
		font-size: 18px;
	}
	.solution-card{
		margin: -12px;
	}
	.contact-us-wrapper{
		font-size:22px !important;
		font-style: normal;
		font-weight: 700;
		line-height: 36px;
	}
	.hidden-wrapper{
		position:relative;
		background: #1A1A1A;
		margin-top: -527px;
		width:100%;
		height:100%;
		border-radius:25px;
		display:block;
	}
	.hover-div{
		display:none !important;
	}
	.feature-proof-brandCards {
	width: 31%;
	height: 530px !important;
	border-radius: 14px;
	border: 2px solid var(--imagine-brand-color, #EDD46F);
	background: #1A1A1A;
  }
  .solutions-main-wrapper .view-solutions-txt{
	display:none;
  }
 }
 @media only screen and (min-width: 600px) {
	.feature-proof-brandCards{
		width: 43% !important;
	}
	.banner-image-imagine{
		margin:0;
		width: 100%;
	}
	.goto-btn{
		width: 320px;
	}
	.section-title{
		font-size:40px !important;
	}
	.award-winning-content{
		font-size:24px !important;
	}
	.imagine-comprehensive-txt{
		font-size:24px !important;
	}
	.comprehensive-solution-features{
		font-size: 28px;
	}
	.contact-us-wrapper{
		font-size:24px !important;
		font-style: normal;
		font-weight: 700;
		line-height: 36px;
	}
	.banner-image-imagine{
		margin: -350px 12% !important;
		position:absolute;
		width:540px;
	}
	.seeMore-btn{
		display:none;
	}
	.feature-proof-brandCards {
	width: 31%;
	height: 500px !important;
	border-radius: 14px;
	border: 2px solid var(--imagine-brand-color, #EDD46F);
	background: #1A1A1A;
  }
  .hidden-wrapper{
	display:none;
  }
  .solutions-main-wrapper .view-solutions-txt{
	display:block;
  }
  .solutions-main-wrapper .view-solutions-txt{
	position: absolute;
	bottom: 10px;
  }
 }
 @media only screen and (min-width: 768px) {
	.feature-proof-brandCards {
	height: 430px !important;
  }
  .solutions-main-wrapper .view-solutions-txt{
	position: absolute;
	bottom: 40px;
  }
 }
  @media only screen and (min-width: 1280px) {
	.feature-proof-brandCards{
		width: 32% !important;
	}
	.banner-image-imagine{
		margin: -320px 23.5% !important;
		position:absolute;
		width: inherit;
		height: 151.797px;
	}
	.origin-brandCard{
		margin:0 40px;
	}
	.goto-btn{
		width: 320px;
	}
	.section-title{
		font-size:40px !important;
	}
	.award-winning-content{
		font-size:24px !important;
	}
	.imagine-comprehensive-txt{
		font-size:24px !important;
	}
	.contact-us-wrapper{
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: 36px;
	}
	.band-imgWrapper{
		margin: -325px auto !important;
	}
	.hidden-wrapper{
		display:none;
	}
 }
 @media only screen and (min-width: 1536px) {
	.banner-image-imagine{
		margin: -320px 20% !important;
		position:absolute;
		width: inherit;
		height: 151.797px;
	}
 }
 @media only screen and (min-width: 1920px) {
	.banner-image-imagine{
		margin: -320px 24% !important;
		position:absolute;
		width: inherit;
		height: 151.797px;
	}
 }
  .goto-btn{
	font-family: Jost;
    margin: 0 auto;
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    border: 1px solid var(--imagine-brand-dark, #847021);
    background: var(--imagine-brand-color, #EDD46F);
  }
  .goto-btn-solutions{
	text-decoration: none;
    color: #000;
    font-family: Jost;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }
  .card-info {
	display: inline-block;
  }
  .goto-btn a{
	color: #000;
    text-decoration: none;
	color: #000;
	font-family: Jost;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
  }
  .goto-btn a img{
	width: 32.318px;
    height: 21.311px;
  }
  .imagine-comprehensive-txt{
	font-family: Jost;
	width: 1066px;
  }
  .divider{
	border-color:#EDD46F;
	border-width: thin 0px 1px;
  }
  .brand-imgs{
	height: 35.384px;
  }
  .feature-card-header .brand-images{
	height: 32px;
	padding: 0 20px;
  }
  .hover-content-wrapper .sub-brand-image{
	height: 28px;
  }
  .hover-content-wrapper .aideDataHub-img{
	height: 37px;
  }
  .hover-content-wrapper .aideBI-image{
	height: 21px;
  }
  .feature-card-wrapper{
	display: flex;
    justify-content: center;
    align-items: center;
  }
  .feature-card-header{
	display: flex;
	padding: 24px 0px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 10px;
	align-self: stretch;
	height:92px;
	border-bottom: 2px solid var(--imagine-brand-color, #3D3D3D);
  }
  .text-center{
	margin:0 auto;
  }
  .feature-card-bodyTxt{
	color: #FFF;
    font-family: Jost;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    line-height: 180%;
  }
  .hover-txt-wrapper{
	color: #FFF;
	font-family: Jost;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	padding: 6px 0 36px;
  }
  .hover-content-wrapper{
	display: flex;
	padding: 0 20px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
  }
  .feature-brandCards{
	display:flex;
	flex-wrap: wrap;
	justify-content:center;
	align-item: center;
	margin:0 auto;
	flex-direction:row;
  }
  .feature-proof-brandCards {
	width: 31%;
	height: 430px;
	border-radius: 14px;
	border: 2px solid var(--imagine-brand-color, #3D3D3D);
	background: #1A1A1A;
  }
  .main-div {
    width: 100%;
    height: 100%;
    transition: opacity 0.3s;
	position: relative;
  }
  .solution-brands-title-section{
	font-family: 'JostMedium';
	font-size: 40px;
	font-style: normal;
	font-weight: 500;
	line-height: 51px;
  }
  .hoverBrand-images{
    width: 165px;
  }
  .section-title{
    font-family: 'JostMedium';
  }
  .hover-txt-bolt-origin {
    color: #FFF;
    font-family: Jost;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    padding: 10px 0 36px;
}
.comprehensive-solution-features{
	fontWeight: 500; 
	fontSize: 28px;
	lineHeight: 110%; 
	font-weight: 500;
    font-family: 'JostMedium';
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px 0; 
  background: radial-gradient(40% 40% at 50% 50%, rgba(255, 255, 255, 0.00) 0%, #f5f5f5 100%);
  position: relative;
  margin-top: -50px;
}
.grid-item {
  border: 3px solid #d2d2d226;
  border-left: none;
  border-bottom:none;
  padding: 60px 50px;
  text-align: center;
  z-index: 0;
}
.grid-item:nth-child(1), .grid-item:nth-child(2), .grid-item:nth-child(3) {
  border-top: none !important;
}
.grid-item:nth-child(3), .grid-item:nth-child(6), .grid-item:nth-child(9), .grid-item:nth-child(12) {
  border-right: none !important;
}
.seeMore-btn{
	color: var(--imagine-brand-color, #EDD46F);
    font-family: Jost;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
	position: absolute;
	bottom: 10px;
    left: 20px;
	cursor: pointer;
}
.seeLess-btn{
	color: var(--imagine-brand-color, #EDD46F);
    font-family: Jost;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
	position: absolute;
	bottom: 10px;
	left: 20px;
	cursor: pointer;
}
.hover-div-wrapper .imagine-solutions-btn .load-more-btn{
    border-radius: 4px;
    border: 1px solid #FFF;
    padding: 10px 24px;
    color: #fff;
    text-decoration: none;
	font-family: Jost;
    font-style: normal;
    font-weight: 400;
	position: absolute;
	bottom: 40px;
	left: 20px;
}
.hidden-wrapper .imagine-solutions-btn .load-more-btn {
	border-radius: 4px;
    border: 1px solid #FFF;
    padding: 10px 24px;
    color: #fff;
    text-decoration: none;
	font-family: Jost;
    font-style: normal;
    font-weight: 400;
	position: absolute;
	bottom: 100px;
	left: 20px;
}
.solutions-main-wrapper .view-solutions-txt{
	color: #edd46f;
	font-family: Jost;
    font-style: normal;
    font-weight: 400;
	position: absolute;
	left: 20px;
    font-size: 20px;
}
.view-solutions-txt .btnarrow {
    cursor: pointer;
    display: inline-block;
    height: 12px;
    margin-left: 0.5em;
    position: relative;
    transition: width 0.2s ease;
    vertical-align: middle;
    width: 20px;
    transition: color .3s,background .3s,border .3s;
    transition: width 0.3s ease;
}
.view-solutions-txt .btnarrow::before {
    border-bottom: 2px solid currentColor;
    content: "";
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}
.view-solutions-txt .btnarrow::after {
    border-right: 2px solid currentColor;
    border-top: 2px solid currentColor;
    content: "";
    display: block;
    height: 10px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 10px;
}
.grid-container-wrapper{
	padding-bottom: 240px;
	margin-top:-90px;
}
.bannerImage-imagineTech{
	height: 151.797px;
}
.imagineBanner-contentSection{
	margin-bottom: 150px;
}
.band-imgWrapper{
	text-align: center;
	position: absolute;
	margin: -350px auto;
	left: 0;
	right: 0;
}
.stakeholders-card-wrapper{
	width: 334px; 
	border-bottom:2px solid #EDD46F;
	font-family: Jost; 
	font-weight: 600;
	font-size: 18px; 
	line-height: 160%; 
	background:#f5f5f5;
}
</style>
