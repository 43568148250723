<template lang="html">
	<v-layout column id="contact-us-form">
		<v-flex style="padding:40px 0">
			<transition name="fade-contact">
				<v-layout row wrap align-center justify-space-around v-if="!contactRequestSuccess" key="contact-1">
					<v-flex xs12 md6 class="contact-form" mt-12 :style="{'margin-top':$vuetify.breakpoint.smAndUp?'0':'20px'}">
						<V-flex id="hubspotForm" v-once></v-flex>
					</v-flex>
					<div class="vl" md-none style="border:1px solid #666;opacity: 0.248; height: 440px;"></div>
					<v-flex xs12 md4 mt-12 :class="{'pr-5':$vuetify.breakpoint.smAndUp}">
						<div class="whatHappensNxt-wrapper">
							<div class="whatHappensNxt-title">What happens next?</div>
							<div class="nxtSteps-wrapper">
								<div class="nxt-section">
									<div class="nxt-wrapper-container">
										<div class="list-content-section">01</div>
									</div>
									<div class="nxtSteps-content-info">Our sales manager reaches out to<br />you within few days.</div>
								</div>
								<div class="nxt-step-divider ml-5 my-5"></div>
								<div class="nxt-section">
									<div class="nxt-wrapper-container">
										<div class="list-content-section">02</div>
									</div>
									<div class="nxtSteps-content-info">Setup a meeting with our experts to<br />understand your requirements.</div>
								</div>
								<div class="nxt-section">
									<div class="nxt-wrapper-container">
										<div class="list-content-section">03</div>
									</div>
									<div class="nxtSteps-content-info">We propose project estimate and<br />timeline.</div>
								</div>
							</div>
						</div>
					</v-flex>
				</v-layout>
				<v-layout row wrap v-else key="contact-2">
					<v-flex xs12 :style="{'font-size':$vuetify.breakpoint.smAndUp?'40px':'30px'}" style="font-family:JostBold;padding-bottom:80px">
						Thank you for contacting us!
					</v-flex>
				</v-layout>
			</transition>
		</v-flex>
	</v-layout>
</template>

<script>
  export default {
    mounted() {
    const script = document.createElement("script");
    script.src="https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "7368417",
          formId: "b7e4c6cb-0c40-420b-bde5-5366b487febd",
          target: "#hubspotForm"
        })
      }
    })
  }
}
</script>

<style lang="css" scoped>
.contact-header{
	font-family: JostBold;
}
.contact-subtitle{
	margin-top:20px;
	font-family: JostMedium;
}
.contact-form >>> .v-input__slot{
	background:transparent!important;
	border-bottom:1px solid #353839;
	border-radius:0!important;
	font-size:20px;
}
.contact-form >>> .v-select__selection--comma {
	padding-bottom:2px;
}
.contact-form >>> label{
	font-size:20px;
	color:#999;
	font-weight: normal
}
.contact-fade-enter-active,
.contact-fade-leave-active {
	transition-duration: 0.5s;
	transition-timing-function: ease;
}
.contact-fade-enter,
.contact-fade-leave-active {
	transform: translateY(-100%);
	opacity:0;
}
.fade-contact-enter-active,
.fade-contact-leave-active {
	transition-duration: 0.3s;
	transition-property: opacity;
	transition-timing-function: ease;
}
.fade-contact-enter,
.fade-contact-leave-active {
	opacity: 0
}
.list-number{
	width: 30.71px;
	height: 30px;
    padding: 3px 7px 0;
    background: #EDD46F;
}
.list-txt{
	color: #000;
    font-family: JostMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
	margin-left: 10px;
}
@media (max-width: 992px) {
    .vl {
         display: none;
    }
}
.whatHappensNxt-wrapper {
	width: 290px;
	height: 345px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 40px;
	display: inline-flex;
	margin-bottom:96px;
}
.whatHappensNxt-title {
	color: #121212;
	font-size: 24px;
	font-family: Jost;
	font-weight: 700;
	word-wrap: break-word;
}
.nxtSteps-wrapper {
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 57px;
	display: flex;
}
.nxt-section {
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	display: inline-flex;
}
.nxt-wrapper-container {
	width: 40px;
	height: 40px;
	padding: 10px;
	background: #EDD46F;
	border-radius: 4px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	display: inline-flex;
}
.list-content-section {
	color: black;
	font-size: 18px;
	font-family: Jost;
	font-weight: 700;
	word-wrap: break-word;
}
.nxtSteps-content-info {
	color: black;
	font-size: 16px;
	font-family: Jost;
	font-weight: 400;
	line-height: 26px;
	word-wrap: break-word;
}
.nxt-step-divider {
    width: 215.71px; 
    height: 0px; 
    transform: rotate(90deg); 
    transform-origin: 0 0; 
    opacity: 0.25; 
    border: 0.50px #EDD46F solid;
	position: absolute;
}
</style>
